import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './App.css'; // Use the same CSS for consistency

const VideoUpload = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [analysisResult, setAnalysisResult] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [weapon_detected, setWeaponDetected] = useState(false); // Add this line

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    setSuccessMessage('');
    setAnalysisResult('');
    setError('');
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!selectedFile) {
      alert('Please select a file to upload.');
      return;
    }

    const formData = new FormData();
    formData.append('file', selectedFile);

    try {
      setLoading(true);
      setError('');
      setSuccessMessage('');
      setAnalysisResult('');

      const response = await axios.post('https://api.aistudio.dci.in/upload-video', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.data && response.data.result) {
        setAnalysisResult(response.data.result);
        setSuccessMessage('File uploaded and analyzed successfully!');

        // Check if "weapons" is detected in the result and update the state
        if (response.data.weapon_detected) {
          setWeaponDetected(!weapon_detected);
        } else {
          setWeaponDetected(weapon_detected);
        }
      } else {
        throw new Error('Invalid response format');
      }
    } catch (error) {
      setError('Error uploading file. Please check the file format and try again.');
      console.error('Error uploading file:', error);
    } finally {
      setLoading(false);
    }
  };
console.log(weapon_detected)
  return (
    <div className="app-container">
      <h1>Upload and Analyze Video</h1>
      {error && <p className="error-message">{error}</p>}
      {successMessage && <p className="success-message">{successMessage}</p>}
      <form onSubmit={handleSubmit} className="upload-form">
        <input
          type="file"
          accept="video/*"
          onChange={handleFileChange}
          className="file-input"
        />
        <center><button type="submit" className="button" disabled={loading}>
          {loading ? 'Uploading...' : 'Upload & Analyze'}
        </button>
        </center>
      </form>

      {analysisResult && (
        <center>
        <div
          className="result-container"
          style={{ backgroundColor: weapon_detected ?  'var(--result-container-background)' : 'rgb(255, 105, 105)'}}
        >
          <h2>Analysis Result:</h2>
          <p dangerouslySetInnerHTML={{ __html: analysisResult }}></p>
        </div>
        </center>
      )}

      {loading && <p>Loading...</p>}
    </div>
  );
};

export default VideoUpload;
