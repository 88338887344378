import React from "react";
import { Link } from "react-router-dom";
import DarkMode from "./DarkMode";
import { FaHome, FaVideo, FaUpload } from "react-icons/fa";
import "./Navbar.css";

export default function Navbar() {
    return (
        <nav className="custom-navbar">
            <div className="custom-navbar-logo">Surveillance Analysis</div>
            <ul className="custom-navbar-links">
                <li>
                    <Link to="/" className="custom-nav-link" title="Dashboard">
                        <FaHome className="nav-icon" />
                    </Link>
                </li>
                <li>
                    <Link to="/stream" className="custom-nav-link" title="Live Stream">
                        <FaVideo className="nav-icon" />
                    </Link>
                </li>
                <li>
                    <Link to="/upload" className="custom-nav-link" title="Upload Video">
                        <FaUpload className="nav-icon" />
                    </Link>
                </li>
                <li className="custom-dark-mode-toggle">
                    <DarkMode />
                </li>
            </ul>
        </nav>
    )
}