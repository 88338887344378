import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import VideoUpload from './VideoUpload'; 
import VideoStreamPage from './VideoStreamPage'; 
import Dashboard from './Dashboard';
import DarkMode from './DarkMode';
import Navbar from './Navbar';
import './App.css'; // Global styles
import { FaHome, FaCloudUploadAlt, FaCamera } from 'react-icons/fa';

const App = () => {
  return (
    
    <Router>
      
        <nav className="navbar">
          <Navbar/>
          <DarkMode/>
          
        </nav>
        <div className="content">
          <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/upload" element={<VideoUpload />} />
          <Route path="/stream" element={<VideoStreamPage />} />
          
          </Routes>
          
        </div>
    </Router>
    

  );
};

export default App;
