import React, { useState, useRef } from 'react';
import './VideoStream.css';


const VideoStreamPage = () => {
  const [cameras, setCameras] = useState([{ id: 1, rtspUrl: '' }]); // Dynamic camera list
  const [loading, setLoading] = useState({});
  const videoRefs = useRef([]);
  const socketRefs = useRef([]);
  const [inferences, setInferences] = useState({});
  const [statuses, setStatuses] = useState({});
  const [detectionResults, setDetectionResults] = useState({});

  const startCamera = (index, rtspUrl) => {
    setLoading((prev) => ({ ...prev, [index]: true }));

    try {
      const ws = new WebSocket(`wss://api.aistudio.dci.in/ws/${index}`);
      socketRefs.current[index] = ws;

      ws.onopen = () => {
        console.log(`WebSocket connected for Camera ${index + 1}`);
        ws.send(JSON.stringify({ rtspUrl })); // Send RTSP URL to the backend
        setStatuses((prev) => ({ ...prev, [index]: 'Connected' }));
      };

      ws.onclose = () => {
        console.log(`WebSocket closed for Camera ${index + 1}`);
        setStatuses((prev) => ({ ...prev, [index]: 'Disconnected' }));
      };

      ws.onerror = (error) => {
        console.error(`WebSocket error for Camera ${index + 1}: `, error);
        setStatuses((prev) => ({ ...prev, [index]: 'WebSocket Error' }));
        setLoading((prev) => ({ ...prev, [index]: false }));
      };

      ws.onmessage = (event) => {
        const data = JSON.parse(event.data);
        handleIncomingData(data, index);
      };
    } catch (error) {
      console.error(`Error starting camera ${index + 1}: `, error);
      setStatuses((prev) => ({ ...prev, [index]: 'Error starting camera' }));
      setLoading((prev) => ({ ...prev, [index]: false }));
    }
  };

  const handleIncomingData = (data, index) => {
    if (data.frame) {
      // Draw frame on the canvas
      const img = new Image();
      img.src = `data:image/jpeg;base64,${data.frame}`;

      img.onload = () => {
        if (videoRefs.current[index]) {
          const canvas = videoRefs.current[index];
          const ctx = canvas.getContext('2d');

          ctx.clearRect(0, 0, canvas.width, canvas.height);

          const imgAspectRatio = img.width / img.height;
          const canvasAspectRatio = canvas.width / canvas.height;

          let drawWidth, drawHeight, offsetX, offsetY;

          if (imgAspectRatio > canvasAspectRatio) {
            drawWidth = canvas.width;
            drawHeight = canvas.width / imgAspectRatio;
            offsetX = 0;
            offsetY = (canvas.height - drawHeight) / 2;
          } else {
            drawHeight = canvas.height;
            drawWidth = canvas.height * imgAspectRatio;
            offsetX = (canvas.width - drawWidth) / 2;
            offsetY = 0;
          }

          ctx.drawImage(img, offsetX, offsetY, drawWidth, drawHeight);
        }
      };
    }

    if (data.inferences) {
      const inferenceData = data.inferences || [];
      setInferences((prev) => ({ ...prev, [index]: inferenceData }));

      const newDetectionResults = {
        numPersons: inferenceData.person_count || 0,
        runningDetected: inferenceData.running_status === 'Running Detected',
        cameraView: inferenceData.obstruction ? 'Blocked' : 'Normal',
        weaponDetected: inferenceData.weapon_status === 'Weapon Detected',
      };

      setDetectionResults((prev) => ({ ...prev, [index]: newDetectionResults }));
    }
  };

  const toggleCamera = (index) => {
    if (statuses[index] === 'Connected') {
      stopCamera(index);
    } else {
      const rtspUrl = prompt('Enter the RTSP URL for this camera:');
      if (rtspUrl) {
        startCamera(index, rtspUrl);
      }
    }
  };

  const stopCamera = (index) => {
    if (socketRefs.current[index]) {
      socketRefs.current[index].close();
    }
    setStatuses((prev) => ({ ...prev, [index]: 'Disconnected' }));
  };

  const addCamera = () => {
    setCameras((prev) => [...prev, { id: prev.length + 1, rtspUrl: '' }]);
  };

  const removeCamera = (index) => {
    stopCamera(index);
    setCameras((prev) => prev.filter((camera, i) => i !== index));
  };

  return (
    <div className="dashboard-page">
      {/* Navbar */}
      <div className="app-container">
        <h1>LIVE CCTV MONITORING</h1>
        <button className="addbutton" onClick={addCamera}>Add Camera</button>
        <br />
        <div className="grid-container">
          {cameras.map((camera, index) => (
            <div key={index} className="camera-grid">
              <h2>Camera {camera.id}</h2>
              <p>Status: {statuses[index] || 'Disconnected'}</p>
              {loading[index] && <p>Streaming</p>}
              <button className="button" onClick={() => toggleCamera(index)}>
                {statuses[index] === 'Connected' ? 'Stop Camera' : 'Start Camera'}
              </button>
              {statuses[index] === 'Connected' ? (
                <div className="stream-container">
                  <canvas
                    ref={(el) => (videoRefs.current[index] = el)}
                    className="stream-video"
                    width="1920"  // 16:9 aspect ratio
                    height="1080"
                  />
                  <div className="inference-area">
                    <div className="inference-details">
                      <p>Number of Persons: {detectionResults[index]?.numPersons}</p>
                      <p>Running Detected: {detectionResults[index]?.runningDetected ? 'Yes' : 'No'}</p>
                      <p>Camera View: {detectionResults[index]?.cameraView}</p>
                      <p>Weapon Detected: {detectionResults[index]?.weaponDetected ? 'Weapon Found' : 'No Weapons'}</p>
                    </div>
                  </div>
                </div>
              ) : (
                <p></p>
              )}
              <button className='removebutton' onClick={() => removeCamera(index)}>Remove Camera</button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default VideoStreamPage;

