import React, { useEffect } from "react";
import { ReactComponent as Sun } from "./Sun.svg";
import { ReactComponent as Moon } from "./Moon.svg";
import "./DarkMode.css";

const DarkMode = () => {
  // Function to apply dark mode
  const setDarkMode = () => {
    document.body.setAttribute('data-theme', 'dark');
    localStorage.setItem("selectedTheme", "dark");
  };

  // Function to apply light mode
  const setLightMode = () => {
    document.body.setAttribute('data-theme', 'light');
    localStorage.setItem("selectedTheme", "light");
  };

  // Retrieve the selected theme from local storage
  const selectedTheme = localStorage.getItem("selectedTheme");

  useEffect(() => {
    // Apply the saved theme on component mount
    if (selectedTheme === "dark") {
      setDarkMode();
    } else {
      setLightMode();
    }
  }, [selectedTheme]);

  // Toggle between dark and light themes
  const toggleTheme = (e) => {
    if (e.target.checked) {
      setDarkMode();
    } else {
      setLightMode();
    }
  };

  return (
    <div className='dark_mode'>
      <input
        className='dark_mode_input'
        type='checkbox'
        id='darkmode-toggle'
        onChange={toggleTheme}
        defaultChecked={selectedTheme === "dark"}
      />
      <label className='dark_mode_label' htmlFor='darkmode-toggle'>
        <Sun />
        <Moon />
      </label>
    </div>
  );
};

export default DarkMode;
