import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaCloudUploadAlt, FaCamera } from 'react-icons/fa';
import './Dashboard.css';
import Navbar from './Navbar';
import DarkMode from './DarkMode';





const Dashboard = () => {
  const [text, setText] = useState('');

  const fullText = `  Detect Suspicious Activities..!`;

  useEffect(() => {
    let index = 0;
    const interval = setInterval(() => {
      if (index < fullText.length) {
        setText((prev) => prev + fullText.charAt(index));
        index++;
      } else {
        clearInterval(interval);
      }
    }, 100);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="dashboard-page">
     

      {/* Header Section */}
      <div className="dashboard-image-container">
        <div className="overlay-text">{text}</div>
      </div>

    

      <div className='features'>
        <div className='rows'>
          <div className='text-col'>
            <h2>About The Model</h2>
            <p>The Suspicious Activity Detection (SAD) model analyzes video footage in real-time or from uploads 
            to detect suspicious behavior. It helps security professionals monitor areas effectively.</p>
          </div>
          <div class="img-col">
            <img src='/abtmdl.avif'/>
          </div>
        </div>

        <div class="rows">
            <div class="img-col">
                <img src="/im1.jpg" />
            </div>
            <div class="text-col">
                <h2>How To use the Model</h2>
                <p>Upload a video for analysis or use the live stream feature to monitor real-time CCTV footage. 
                The model will analyze and report any suspicious activities.</p>
            </div>
            
        </div>

        <div className='rows'>
          <div className='text-col'>
            <h2>Upload Video Clips</h2>
            <p>Upload your video clips now to enable real-time analysis for detecting suspicious activities. 
              Enhance your security measures with our advanced detection model!</p>
          </div>
          <div class="img-col">
          <img src="/uploadimg.gif" />
          <Link to="/upload" className="feature-link">Upload Video</Link>
          </div>
        </div>

        <div class="rows">
            <div class="img-col">
                <img src="/liveimg.gif" />
                <Link to="/stream" className="feature-link">Live Stream</Link>
            </div>
            <div class="text-col">
                <h2>Live Stream Analysis</h2>
                <p>Monitor your surroundings with our live stream analysis for real-time suspicious activity detection.
                  Stay alert and safeguard your environment effortlessly!.</p>
            </div>
            
        </div>
          
      </div>


    

      {/* Contact Section */}
      <footer className="contact-section">
        <h3>Contact Us</h3>
        <p>Email: support@sadmodel.com</p>
        <p>Phone: +123-456-7890</p>
      </footer>
    </div>
  );
};

export default Dashboard;
